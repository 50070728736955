import PropTypes from "prop-types"
import React from "react"
import TextLoop from "react-text-loop";
import "./welcome.scss"

const Welcome = ({ siteTitle }) => (
  <header className="welcome-text">
    <h2>
      <TextLoop style={{}} mask={true}>
          {siteTitle.map((title)=>
          {return title}
          )}
      </TextLoop>
    </h2>
  </header>
)

Welcome.propTypes = {
  siteTitle: PropTypes.array,
}

Welcome.defaultProps = {
  siteTitle: ['svagatham','bonjour','welcome'],
}

export default Welcome
